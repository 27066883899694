import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M1.00003 13V9L5.14251 6.5145C5.61609 6.23035 6.23035 6.38391 6.5145 6.8575L7.01084 7.68472C7.2849 8.14149 7.8689 8.30325 8.33892 8.05258L13.5294 5.28431C14.1955 4.92906 15 5.41175 15 6.16667V13C15 14.1046 14.1046 15 13 15H3.00003C1.89546 15 1.00003 14.1046 1.00003 13Z"
        fill="#B0B0B0"
        color="#B0B0B0"
      />
      <path
        d="M1.5 5.5L6.07125 2.75725C6.30804 2.61517 6.61517 2.69196 6.75725 2.92875L7.74275 4.57125C7.88483 4.80804 8.19196 4.88483 8.42875 4.74275L13 2"
        stroke="#B0B0B0"
        strokeWidth="1.5"
        strokeLinecap="round"
        color="#B0B0B0"
      />
      <path
        d="M13.3146 3.67553L11.7029 0.855049C11.6203 0.710547 11.7401 0.534294 11.9048 0.557831L14.7253 0.960758C14.8604 0.980063 14.937 1.12609 14.8759 1.24819L13.6671 3.66575C13.5957 3.80862 13.3938 3.81423 13.3146 3.67553Z"
        fill="#B0B0B0"
      />
    </SvgIcon>
  )
}
