const ResizeS3Server = 'https://d118ok0y28vu16.cloudfront.net'

const loadImg = (url, size = 400) => {
  if (!url) {
    return ''
  }
  if (size === null) {
    return url
  }
  const values = url.split('/')
  const fileName = values && values[values.length - 1]
  let newFileName = fileName
  const dotIndex = fileName.lastIndexOf('.')
  if (dotIndex >= 0) {
    // const fileExt = fileName.substr(dotIndex)
    newFileName = `${fileName.slice(0, dotIndex)}.webp` // fileName.replace(fileExt, '.webp')
  }
  // console.info(`${ResizeS3Server}/${size}New/${newFileName}`)
  return `${ResizeS3Server}/${size}New/${newFileName}`
}

export { loadImg }
