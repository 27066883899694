import { Box, Typography, Divider, Backdrop } from '@mui/material'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import { FC, useState, useMemo, useEffect, ReactNode } from 'react'
import User2 from '@/components/icons/user2'
import { useReactiveVar } from '@apollo/client'
import { userInfoCatch } from '@/lib/catch'
import Router, { useRouter } from 'next/router'
import { statsigLog } from '@/lib/statsigAndGa'
import { automaticLogon, deleteCookie } from '@/lib/user'
import { useIntl } from 'react-intl'
import Link from 'next/link'
import HouseLogo from '@/components/icons/houseLogo'
import { ExternalHeaderUrls, HEADER_LIST_KEY } from '@/interface/header'
import { showActive as stateActive } from '@/state/active'
import { popularShow } from '@/state/popular'
import { showLoginDrawer } from '@/state/login'
import { generatePath } from '@/lib/urlParse'
import usePreventBodyScrolling from '@/hooks/usePreventBodyScrolling'
import Facebook from '../icons/footer/facebook'
import Twitter from '../icons/footer/twitter'
import Instagram from '../icons/footer/instagram'
import Youtube from '../icons/footer/youtube'
import Policy from '../icons/header/policy'
import Guarantee from '../icons/header/guarantee'
import About from '../icons/header/about'
import Faq from '../icons/header/faq'
import Blog from '../icons/header/blog'
import Market from '../icons/header/market'
import Favorite from '../icons/header/favorite'
import Logo from '../icons/logo'
import Rental from '../icons/header/rental'
import { getLastActiveDate } from './activityState'
import ActivityMobile from './activityMobile'

const HEADER_LIST = [
  {
    name: 'header.menu.favorites',
    key: HEADER_LIST_KEY.FAVORITES,
    icon: <Favorite className="w-4 h-4" />,
    href: '/favorites',
    auth: true
  },
  {
    name: 'header.menu.rental',
    key: HEADER_LIST_KEY.RENTAL,
    icon: <Rental className="w-4 h-4" />,
    href: '',
    auth: false
  },
  {
    name: 'header.menu.blog',
    key: HEADER_LIST_KEY.BLOG,
    icon: <Blog className="w-4 h-4" />,
    href: '/blog',
    auth: false
  },
  {
    name: 'header.menu.faq',
    key: HEADER_LIST_KEY.FAQ,
    icon: <Faq className="w-4 h-4" />,
    href: '/faq',
    auth: false
  },
  {
    name: 'header.menu.logout',
    key: HEADER_LIST_KEY.LOGOUT,
    icon: <LogoutRoundedIcon className="w-4 h-4 text-[#B0B0B0]" />,
    click: HEADER_LIST_KEY.LOGOUT,
    auth: true
  }
]

const OTHER_LIST = [
  {
    name: 'header.menu.guarantee',
    key: HEADER_LIST_KEY.GUARANTEE,
    icon: <Guarantee className="w-4 h-4" />,
    href: '/guarantee',
    auth: false
  },
  {
    name: 'header.menu.about',
    key: HEADER_LIST_KEY.ABOUT,
    icon: <About className="w-4 h-4" />,
    href: '/about',
    auth: false
  },
  {
    name: 'header.menu.policy',
    key: HEADER_LIST_KEY.POLICY,
    icon: <Policy className="w-4 h-4" />,
    href: '/policy',
    auth: false
  },
  {
    name: 'header.menu.market',
    key: HEADER_LIST_KEY.MARKET_TRENDS,
    icon: <Market className="w-4 h-4" />,
    href: '/market-trends/los-angeles',
    auth: false
  }
]

const CURRENT_YEAR = new Date().getFullYear()

// 需要鉴权才能进入的界面
const NEED_AUTH_PAGE = ['/favorites']
const MobileHeader: FC<{
  showLogo?: boolean
  transparentMode?: boolean
  showActive?: boolean
  className?: string
  back?: boolean
  centerNode?: ReactNode
}> = ({
  showLogo = false,
  transparentMode = false,
  showActive = true,
  className = '',
  back = false,
  centerNode = null
}) => {
  const { name } = useReactiveVar(userInfoCatch)
  const [menuVisible, setMenuVisible] = useState<boolean>(false)
  const intl = useIntl()
  const router = useRouter()
  const activeDisplay = useReactiveVar(stateActive)
  const shouldTop = useMemo(
    () => showActive && activeDisplay && !transparentMode,
    [showActive, activeDisplay, transparentMode]
  )

  usePreventBodyScrolling(menuVisible)

  useEffect(() => {
    stateActive(!!getLastActiveDate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logInOrOut = (type: HEADER_LIST_KEY.LOGIN | HEADER_LIST_KEY.LOGOUT) => {
    setMenuVisible(false)
    if (type === HEADER_LIST_KEY.LOGOUT) {
      // 登出
      automaticLogon()
      deleteCookie()
      userInfoCatch({ email: '', name: '', userId: '', firstName: '', lastName: '' })
      statsigLog('login_out_click', { isMobile: true })
      const isNeedAuth = NEED_AUTH_PAGE.some(path => router.pathname.startsWith(path))
      if (isNeedAuth) {
        Router.replace('/')
      } else {
        Router.reload()
      }
    } else if (!name) {
      showLoginDrawer(true)
    }
  }

  const jumpNav = (selected: HEADER_LIST_KEY) => {
    setMenuVisible(false)
    statsigLog('mobile_header_menu_click', { menu: selected })
    switch (selected) {
      case HEADER_LIST_KEY.LOGOUT:
        logInOrOut(selected)
        break
      case HEADER_LIST_KEY.RENTAL:
        window.open(ExternalHeaderUrls[HEADER_LIST_KEY.RENTAL], '_blank')
        break
      default:
        break
    }
  }

  const openSearch = () => {
    setMenuVisible(false)
    popularShow(true)
    statsigLog('popular_searches_click')
  }

  const backPage = () => {
    window.location.href = generatePath()
  }

  const list = [
    {
      url: 'https://www.facebook.com/RentyAi',
      icon: <Facebook className="w-5 h-5" />,
      title: 'Tripalink Facebook'
    },
    {
      url: 'https://twitter.com/RentyAi',
      icon: <Twitter className="w-5 h-5" />,
      title: 'Tripalink Twitter'
    },
    {
      url: 'https://instagram.com/renty.ai?igshid=OGQ5ZDc2ODk2ZA==',
      icon: <Instagram className="w-5 h-5" />,
      title: 'Tripalink Instagram'
    },
    {
      url: 'https://www.youtube.com/channel/UCUFb-jAiIo8bsVgYHEfXpTQ',
      icon: <Youtube className="w-5 h-5" />,
      title: 'Tripalink Youtube'
    }
  ]

  const headLeft = back ? (
    <Box className="px-2" component="div" onClick={backPage}>
      <KeyboardArrowLeftRoundedIcon className="text-2xl text-primary" />
    </Box>
  ) : (
    <>
      <Logo className="text-xl" />
      <div className="text-black-headerBlack ml-2 text-[15px] font-semibold">Renty</div>
      <div className="text-brand text-[15px] font-semibold">.AI</div>
    </>
  )

  return (
    <>
      {showActive && <ActivityMobile />}
      <Box
        component="div"
        className={`${className} ${
          transparentMode
            ? 'bg-[transparent] fixed'
            : `bg-white ${router.asPath === '/' ? 'fixed' : 'sticky'}`
        } left-0 w-full z-40 px-5 py-0 flex items-center justify-between`}
        style={{ top: shouldTop ? '2.25rem' : '0' }}
      >
        {showLogo ? (
          <Link href="/" className="flex items-center">
            {!transparentMode ? (
              headLeft
            ) : (
              <Box className="bg-[#D3DDCC] p-[5px] rounded-[6px] leading-4">
                <Logo className="text-base" />
              </Box>
            )}
          </Link>
        ) : (
          <div className="w-2" />
        )}
        {centerNode}
        <Box
          onClick={() => setMenuVisible(true)}
          className={`${
            transparentMode ? 'bg-[transparent]' : 'bg-white'
          } rounded-xl shadow-[0px_2px_4px_rgba(0, 0, 0, 0.1)] p-3 flex w-fit`}
        >
          {name ? (
            <Typography
              className={`${
                transparentMode ? 'text-white' : ''
              } font-averta font-bold block w-5 h-5 text-center'
              `}
            >
              {name.charAt(0).toLocaleUpperCase()}
            </Typography>
          ) : (
            <User2 className="w-5 h-5" color={transparentMode ? 'white' : 'dark'} />
          )}
        </Box>
      </Box>

      {/* drawer组件无法在服务端渲染 */}
      <Backdrop className="z-[1299]" open={menuVisible} onClick={() => setMenuVisible(false)} />
      <Box
        component="div"
        className="fixed bg-white bottom-0 top-0 right-0 overflow-x-hidden overflow-y-scroll hide-scrollbar transition-transform"
        sx={{
          zIndex: 1300,
          transform: menuVisible ? 'translateX(0)' : 'translateX(100%)'
        }}
      >
        <Box
          component="div"
          className="bg-white w-60 h-[100%] pl-5 py-5 flex flex-col justify-between"
        >
          <Box
            component="div"
            className="py-10 flex items-center"
            onClick={() => {
              logInOrOut(HEADER_LIST_KEY.LOGIN)
            }}
          >
            <Box
              component="div"
              className="w-8 h-8 bg-[#E9E9E9] border-2 border-white rounded-full flex items-center justify-center"
              sx={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.10)' }}
            >
              <User2 className="w-5 h-5" color="#717171" />
            </Box>
            <Typography className="text-sm ml-2 text-primary">
              {name || intl.formatMessage({ id: 'header.menu.login' })}
            </Typography>
          </Box>
          <Box component="div" className="flex-1 flex flex-col overflow-y-auto">
            <Divider />
            <Box component="div" className="mt-10">
              {HEADER_LIST.filter(v => (v.auth ? !!name : !v.auth)).map(item => (
                <Box
                  component={item.href ? 'a' : 'div'}
                  onClick={() => jumpNav(item.key)}
                  className="font-semibold text-primary text-sm py-2 mb-3 last:mb-0 flex items-center"
                  key={item.key}
                  data-key={item.key}
                  href={item.href}
                  target="_self"
                >
                  {item.icon}
                  <div className="ml-3">{intl.formatMessage({ id: item.name })}</div>
                </Box>
              ))}
            </Box>
            <Divider className="mt-10 mb-10" />
            {OTHER_LIST.filter(v => (v.auth ? !!name : !v.auth)).map(item => (
              <Box
                component={item.href ? 'a' : 'div'}
                onClick={() => jumpNav(item.key)}
                className="font-semibold text-primary text-sm py-2 mb-3 last:mb-0 flex items-center"
                key={item.key}
                data-key={item.key}
                href={item.href}
                target="_self"
              >
                {item.icon}
                <div className="ml-3">{intl.formatMessage({ id: item.name })}</div>
              </Box>
            ))}
          </Box>
          <Divider />
          <Box component="div" className="pt-7">
            <Box className="flex items-center justify-between opacity-60 pr-5">
              <HouseLogo className=" mr-6" />
              <Box className="gap-3 flex items-center justify-end">
                {list.map(item => (
                  <Link href={item.url} target="_blank" key={item.url} title={item.title}>
                    {item.icon}
                  </Link>
                ))}
              </Box>
            </Box>
            <Box className="mt-5 text-secondary text-xs pr-5">
              © {CURRENT_YEAR} Renty.AI Inc. All Rights Reserved
            </Box>
            <Box
              component="div"
              className="mt-4 flex items-center text-secondary text-xs"
              onClick={openSearch}
            >
              <Box component="span">{intl.formatMessage({ id: 'global.popular.searches' })}</Box>
              <KeyboardArrowRightRoundedIcon className="text-base text-secondary" />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
MobileHeader.displayName = 'MobileHeader'
export default MobileHeader
