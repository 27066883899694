import { useState } from 'react'
import momentjs from 'moment'

export const activityConfig = {
  key: 'lastActivityShow',
  lastActivityDate: ''
}

export const getLastActiveDate = () => {
  if (typeof window !== 'undefined') {
    const localShow = localStorage.getItem(activityConfig.key) || ''
    const today = momentjs().format('YYYY/MM/DD')
    if (localShow !== today) {
      activityConfig.lastActivityDate = '1'
    }
  }
  return activityConfig.lastActivityDate
}

export const setLastActiveDate = (date: string) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(activityConfig.key, date)
  }
}

export const useActiveOpen = (initValue = false) => {
  const [open, setOpen] = useState(initValue)
  return [open, setOpen] as const
}

export const useActiveShow = (initValue = true) => {
  const [show, setShow] = useState(initValue)
  return [show, setShow] as const
}
