import { Box, SvgIcon } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import momentjs from 'moment'
import usePageOffsetTop from '@/hooks/usePageOffsetTop'
import { showActive } from '@/state/active'
import { useIntl } from 'react-intl'
import { useActiveOpen, useActiveShow, setLastActiveDate, getLastActiveDate } from './activityState'

import { calcRestFakeSport, useNoApplicationFeeActivity } from '../../lib/activity'

const Activity = () => {
  const [open, setOpen] = useActiveOpen(false)
  const [show, setShow] = useActiveShow(true)
  const display = useReactiveVar(showActive)
  const [, , setHeaderTop] = usePageOffsetTop()
  const value = calcRestFakeSport()
  const { open: isNoApplicationFeeActivityOpen } = useNoApplicationFeeActivity()
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 3,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#757575'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: '#60E2B8'
    }
  }))
  const showDetail = e => {
    e.stopPropagation()
    e.preventDefault()
    if (isNoApplicationFeeActivityOpen) {
      setOpen(!open)
    }
  }

  const intl = useIntl()

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setShow(false)
    setTimeout(() => {
      showActive(false)
      setHeaderTop()
      setLastActiveDate(momentjs().format('YYYY/MM/DD'))
    }, 160)
  }

  useEffect(() => {
    showActive(!!getLastActiveDate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return display ? (
    <>
      <Box
        className={`sticky left-0 top-0 right-0 z-[999] transition-all ${
          show ? 'opacity-100' : '-translate-y-full opacity-0'
        }`}
        id="activity"
      >
        <Box
          className={`mx-auto max-w-1440 h-12 px-10 flex items-center ${
            isNoApplicationFeeActivityOpen ? 'justify-between' : 'justify-center'
          }`}
          sx={{ background: 'linear-gradient(315deg, #004D34 0%, #04AF78 100%)' }}
          onClick={showDetail}
        >
          <Box className="ml-15">&nbsp;</Box>
          <Box className="flex items-center justify-center text-white">
            <Box className="text-bas mr-2">
              💥{' '}
              <Box
                component="span"
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'ad.title' }) }}
              />
            </Box>
            {isNoApplicationFeeActivityOpen && (
              <>
                <Box
                  className="h-8 w-[1px] opacity-50 mx-5"
                  sx={{
                    background:
                      'linear-gradient(180deg, rgba(3, 134, 92, 0.00) 0%, rgba(255, 255, 255, 0.80) 33.33%, rgba(255, 255, 255, 0.80) 66.67%, rgba(3, 134, 92, 0.00) 100%)'
                  }}
                />
                <Box className="text-base">
                  🎉 Refund of Application Fee for the{' '}
                  <span className="font-bold italic">FIRST 100</span> applicants who sign a lease.
                </Box>
              </>
            )}
          </Box>
          {isNoApplicationFeeActivityOpen && (
            <Box className="text-sm text-gray-300 underline cursor-pointer mr-15">Details</Box>
          )}
        </Box>
        <Box
          className="cursor-pointer absolute top-2.5 right-10 z-[1] w-7 h-7 rounded-full flex items-center justify-center hover:bg-[rgb(68,126,84)]"
          onClick={handleClose}
        >
          <CloseRoundedIcon className="text-lg w-5 h-5 flex items-center justify-center text-white" />
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        classes={{ root: 'top-12' }}
        slotProps={{ backdrop: { classes: { root: 'top-12' } } }}
        PaperProps={{ style: { top: 'unset' } }}
      >
        <Box
          component="div"
          className="relative flex items-center py-14"
          sx={{ background: 'linear-gradient(137deg, #000 0%, #002519 100%)' }}
        >
          <CloseRoundedIcon
            className="absolute z-[1] top-5 right-10 text-white cursor-pointer"
            onClick={() => setOpen(false)}
          />
          <Box component="div" className="flex justify-around text-white w-full">
            <Box className="w-[22.5rem]">
              <Box component="h2" className="text-[1.75rem] leading-10 font-minion font-bold">
                💥 Massive rent special
              </Box>
              <Box component="div" className="text-base mt-4">
                Properties rented through Renty.AI are{' '}
                <span className="text-[#60E2B8] font-bold italic">$30 off</span> per month, saving
                you <span className="text-[#60E2B8] font-bold italic">$360</span> per year.
              </Box>
            </Box>
            <Box className="w-[32rem]">
              <Box component="h2" className="flex items-center">
                <Box className="text-[1.75rem] font-minion font-bold leading-10">
                  🎉 Refund of Application Fee
                </Box>
                <Box className="ml-4">
                  <Box className="flex items-center gap-1 mb-1">
                    <SvgIcon width="12" height="13" viewBox="0 0 12 13">
                      <path
                        d="M8.57853 0.5H4.72771C4.42153 0.5 4.14609 0.686114 4.03186 0.970186L1.91411 6.23668C1.71593 6.72951 2.07877 7.26649 2.60995 7.26649H4.44767L3.69335 11.2235C3.62292 11.5929 4.07757 11.8265 4.33395 11.5514C5.91934 9.84963 9.21657 6.30906 10.676 4.73149C10.8975 4.49214 10.7251 4.1088 10.399 4.1088H8.84446C8.58235 4.1088 8.40111 3.84677 8.49356 3.60151L9.28031 1.51456C9.46523 1.02405 9.10274 0.5 8.57853 0.5Z"
                        fill="#60E2B8"
                      />
                    </SvgIcon>
                    <Box className="text-[#60E2B8] text-xs">Only {value} spots left</Box>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={value} />
                </Box>
              </Box>
              <Box component="div" className="text-base mt-4">
                You heard that right—we’re refunding the <br /> application fee for the{' '}
                <span className="text-[#60E2B8] font-bold italic">first 100</span> applicants who{' '}
                <br /> sign a lease.
              </Box>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  ) : null
}

export default Activity
