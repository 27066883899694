import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M1 3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3Z"
        fill="#B0B0B0"
      />
      <rect x="3" y="4" width="4" height="5" rx="1" fill="white" />
      <rect x="9" y="4" width="4" height="1.5" rx="0.75" fill="white" />
      <rect x="9" y="7" width="4" height="1.5" rx="0.75" fill="white" />
      <rect x="3" y="11" width="10" height="1.5" rx="0.75" fill="white" />
    </SvgIcon>
  )
}
