import { useReactiveVar } from '@apollo/client'
import { Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { statsigLog } from '@/lib/statsigAndGa'
import { loginDialogOpen } from '../../lib/catch'
import LoginContent from './loginContent'

const Login = () => {
  const [value, setValue] = useState('1')
  const loginOpen = useReactiveVar(loginDialogOpen)

  useEffect(() => {
    if (loginOpen) {
      setValue('1')
    }
  }, [loginOpen])

  const handleClose = () => {
    statsigLog('login_in_closed_click')
    loginDialogOpen(false)
  }

  const onSendEmail = () => {
    setValue('2')
  }

  return (
    <Dialog
      className="rounded-3xl"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
          width: '830px',
          maxWidth: '830px'
        }
      }}
      onClose={handleClose}
      open={loginOpen}
    >
      <LoginContent handleClose={handleClose} onSendEmail={onSendEmail} value={value} />
    </Dialog>
  )
}
export default Login
