import { useEffect, useState } from 'react'
import { Stack, Typography, Box, Button, Menu, MenuItem } from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useReactiveVar } from '@apollo/client'
import Router, { useRouter } from 'next/router'
import { statsigLog } from '@/lib/statsigAndGa'
import { useIntl } from 'react-intl'
import { ExternalHeaderUrls, HEADER_LIST_KEY } from '@/interface/header'
import FavoriteIcon from '@/components/icons/favorite'
import { chatActiveProperty } from '@/state/chatActiveProperty'
import { cityRouteMap } from '@/asset/searchData'
import { showActive as stateActive } from '@/state/active'
import { invert } from 'lodash'
import LoginOut from '../icons/login/loginOut'
import User2 from '../icons/user2'
import { userInfoCatch, loginDialogOpen } from '../../lib/catch'
import Login from '../login'
import { automaticLogon, deleteCookie } from '../../lib/user'
import Logo from '../icons/logo'
import { isMapMode, MapModeKEY } from '../../state/config'
import Activity from './activity'

if (typeof window !== 'undefined' && localStorage.getItem(MapModeKEY) === null) {
  // 首次进来，默认地图模式
  localStorage.setItem(MapModeKEY, 'true')
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '-2px 2px 8px 0px #0000001A',
    padding: 0,
    borderRadius: '8px'
  }
}))

const MapSwitch = dynamic(() => import('../mapSwitch'), { ssr: true })

const HEADER_LIST = [
  {
    name: 'header.menu.rental',
    key: HEADER_LIST_KEY.RENTAL,
    href: '',
    eventName: 'navigation_rental_click'
  },
  {
    name: 'header.menu.blog',
    key: HEADER_LIST_KEY.BLOG,
    href: '/blog',
    eventName: 'navigation_blog_click'
  },
  {
    name: 'header.menu.faq',
    key: HEADER_LIST_KEY.FAQ,
    href: '/faq',
    eventName: 'navigation_faq_click'
  },
  {
    name: 'header.menu.about',
    key: HEADER_LIST_KEY.ABOUT,
    href: '/about',
    eventName: 'navigation_about_click',
    children: [
      {
        name: 'header.menu.guarantee',
        key: HEADER_LIST_KEY.GUARANTEE,
        href: '/guarantee',
        eventName: 'navigation_guarantee_click'
      },
      {
        name: 'header.menu.about',
        key: HEADER_LIST_KEY.ABOUT,
        href: '/about',
        eventName: 'navigation_about_click'
      },
      {
        name: 'header.menu.policy',
        key: HEADER_LIST_KEY.POLICY,
        href: '/policy',
        eventName: 'navigation_policy_click'
      },
      {
        name: 'header.menu.market',
        key: HEADER_LIST_KEY.MARKET_TRENDS,
        href: '/market-trends/los-angeles',
        eventName: 'market_trend_entrance_click'
      }
    ]
  }
]
const USER_MENU_LIST = [
  {
    name: 'header.menu.favorites',
    icon: <FavoriteIcon stroke="#001229" className="w-4 h-4" />,
    key: HEADER_LIST_KEY.FAVORITES
  },
  { name: 'header.menu.logout', icon: <LoginOut />, key: HEADER_LIST_KEY.LOGOUT }
]

const NEED_AUTH_PAGE = ['/favorites']

const Header = ({
  isShowMap = false,
  transparentMode = false,
  showActive: handleShowActive = true,
  className = ''
}) => {
  const { name } = useReactiveVar(userInfoCatch)
  const showMap = useReactiveVar(isMapMode)
  const { locale, pathname, asPath } = useRouter()
  const intl = useIntl()
  const { area } = useReactiveVar(chatActiveProperty) || {}
  const areaMap = invert(cityRouteMap)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [dropDownId, setDropDownId] = useState('')
  const display = useReactiveVar(stateActive)

  const onClickLogin = () => {
    loginDialogOpen(true)
    statsigLog('login_in_click')
  }
  const logOut = () => {
    automaticLogon()
    deleteCookie()
    userInfoCatch({ email: '', name: '', userId: '', firstName: '', lastName: '' })
    statsigLog('login_out_click')
    const isNeedAuth = NEED_AUTH_PAGE.some(path => pathname.startsWith(path))
    if (isNeedAuth) {
      Router.replace('/')
    } else {
      Router.reload()
    }
  }
  const onClickUserMenu = (menuKey: HEADER_LIST_KEY) => () => {
    switch (menuKey) {
      case HEADER_LIST_KEY.LOGOUT: {
        logOut()
        break
      }
      case HEADER_LIST_KEY.FAVORITES: {
        statsigLog('my_favorite_property_click')
        Router.push('/favorites')
        break
      }
      default:
        break
    }
  }

  const gotoHomePage = () => {
    statsigLog('navigation_logo_click')
    // 返回首页按钮点击，清空选中property，避免首页search和详情组件同时展示
    chatActiveProperty({})
  }

  const onMapSwitchChange = isSelectedMap => {
    // 缓存用户选择是否显示地图状态
    statsigLog(isSelectedMap ? 'map_mode_click' : 'list_mode_click')
    isMapMode(isSelectedMap)
  }

  useEffect(() => {
    isMapMode(Boolean(localStorage.getItem(MapModeKEY)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLinkHref = header => {
    if (header.name === 'header.menu.market') {
      return `/market-trends/${areaMap[area] || 'los-angeles'}`
    }
    return header.href
  }

  const jumpNav = (key: HEADER_LIST_KEY, eventName: string, e) => {
    if (key === HEADER_LIST_KEY.RENTAL) {
      e.preventDefault()
      window.open(ExternalHeaderUrls[HEADER_LIST_KEY.RENTAL], '_blank')
    }
    statsigLog(eventName)
  }
  const openMenu = Boolean(menuAnchor)
  const itemList = dropDownId ? HEADER_LIST.find(v => v.eventName === dropDownId)?.children : []

  return (
    <>
      {/* 下拉菜单 */}
      <Menu
        id="dropdown-drop"
        classes={{
          paper: 'rounded-lg',
          list: 'rounded-lg gap-2 flex flex-col py-4'
        }}
        disablePortal
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        MenuListProps={{
          'aria-labelledby': dropDownId
        }}
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={() => setMenuAnchor(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiMenu-paper': {
            boxShadow: '-2px 2px 8px 0px rgba(0, 0, 0, 0.10)'
          }
        }}
      >
        {itemList.map(item => (
          <MenuItem disableRipple key={item.name} className="p-0 min-w-[120px]">
            <Link
              target="_self"
              href={item.href}
              className="text-black-headerBlack text-sm px-6 py-2 font-semibold hover:bg-gray-100 w-full flex"
            >
              {intl.formatMessage({ id: item.name })}
            </Link>
          </MenuItem>
        ))}
      </Menu>

      {handleShowActive && <Activity />}

      <Box
        className={`${className} ${display && handleShowActive ? 'top-12' : 'top-0'} ${
          transparentMode
            ? 'bg-[transparent] text-white !top-0 fixed'
            : `bg-white text-primary backdrop-blur-sm transition-all ${
                asPath === '/' ? 'fixed' : 'sticky'
              }`
        } left-0 z-[70] right-0`}
        style={transparentMode ? {} : { boxShadow: '0px 1px 0px #E0E2EF' }}
        id="header"
      >
        <Box className="mx-auto max-w-1440 h-15 px-10 flex justify-between items-center">
          <Link href="/" onClick={gotoHomePage} className="flex items-center cursor-pointer">
            <Box
              className={`${
                !transparentMode ? '' : 'bg-[#D3DDCC] p-[6px] rounded-xl'
              } transition-all`}
            >
              <Logo />
            </Box>
            <Typography
              className={`${
                !transparentMode ? 'text-black-headerBlack' : 'text-[#DEE4DA]'
              } ml-2 font-averta text-2xl font-semibold  transition-all`}
            >
              Renty
            </Typography>
            <Typography
              className={`${
                !transparentMode ? 'text-brand' : 'text-[#DEE4DA]'
              } font-averta text-2xl font-semibold  transition-all`}
            >
              .AI
            </Typography>
          </Link>
          <Box className="flex items-center">
            {HEADER_LIST.map(header =>
              header.children && header.children.length ? (
                <Button
                  key={header.name}
                  aria-owns={openMenu ? 'dropdown-drop' : undefined}
                  aria-haspopup="true"
                  onClick={e => {
                    setDropDownId(header.eventName)
                    setMenuAnchor(e.currentTarget)
                  }}
                  className={`${
                    transparentMode
                      ? 'text-white hover:bg-[rgba(255,255,255,.4)]'
                      : 'text-primary hover:bg-gray-100'
                  } capitalize flex items-center text-black-headerBlack text-sm font-bold font-averta px-4 py-[10px] rounded-xl `}
                  endIcon={
                    openMenu ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />
                  }
                >
                  <span className="mr-1">{intl.formatMessage({ id: header.name })}</span>
                </Button>
              ) : (
                <Link
                  key={header.key}
                  className={`${
                    !transparentMode ? 'hover:bg-gray-100' : 'hover:bg-[rgba(255,255,255,.4)]'
                  } text-black-headerBlack text-sm font-bold font-averta px-4 py-[10px] rounded-xl transition-all`}
                  prefetch={false}
                  href={getLinkHref(header)}
                  onClick={e => {
                    jumpNav(header.key, header.eventName, e)
                  }}
                  locale={locale}
                  target="_self"
                >
                  {intl.formatMessage({ id: header.name })}
                </Link>
              )
            )}
            <LightTooltip
              title={
                name ? (
                  <Box className="bg-white flex flex-col py-4 rounded-lg">
                    {USER_MENU_LIST.map(menu => (
                      <Box
                        key={menu.key}
                        onClick={onClickUserMenu(menu.key)}
                        component="button"
                        className="flex flex-row gap-2 justify-start items-center cursor-pointer capitalize font-semibold text-sm shadow-none border-none text-primary bg-white hover:bg-gray-100 py-[10px] px-6"
                      >
                        {menu.icon}
                        <Typography className="w-108 text-left font-semibold text-sm text-primary font-averta">
                          {intl.formatMessage({ id: menu.name })}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : null
              }
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={1}
                className={`${
                  transparentMode ? ' hover:bg-[rgba(255,255,255,.4)]' : ' hover:bg-gray-100'
                } cursor-pointer max-w-138 px-4 py-[10px] rounded-xl`}
                onClick={() => {
                  if (!name) {
                    onClickLogin()
                  }
                }}
              >
                <User2 className="w-5 h-5" color="dark" />
                <Typography
                  className={`${
                    transparentMode ? 'text-white' : 'text-primary'
                  } font-averta text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis`}
                  component="button"
                >
                  {name || intl.formatMessage({ id: 'header.menu.login' })}
                </Typography>
              </Stack>
            </LightTooltip>
            {isShowMap ? (
              <MapSwitch className="ml-2" isMapMode={showMap} onChange={onMapSwitchChange} />
            ) : null}
          </Box>
          <Login />
        </Box>
      </Box>
    </>
  )
}

export default Header
