import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} fill="none" width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.57968C0 12.6609 3.33914 16 7.42032 16H8.57968C12.6608 16 16 12.6609 16 8.57968V7.42032C16 3.33914 12.6609 0 8.57968 0H7.42032C3.33914 0 0 3.33914 0 7.42032V8.57968ZM5.4387 7.99997C5.4387 6.58541 6.58542 5.43869 7.99998 5.43869C9.41454 5.43869 10.5613 6.58541 10.5613 7.99997C10.5613 9.41452 9.41454 10.5612 7.99998 10.5612C6.58542 10.5612 5.4387 9.41452 5.4387 7.99997ZM6.3333 7.99997C6.3333 8.92045 7.07951 9.66664 7.99998 9.66664C8.92046 9.66664 9.66665 8.92045 9.66665 7.99997C9.66665 7.07948 8.92046 6.3333 7.99998 6.3333C7.07949 6.3333 6.3333 7.07948 6.3333 7.99997ZM10.1731 3.03533C11.7344 3.03533 12.9646 4.26561 12.9646 5.82684C12.9646 5.82684 13 6.90764 13 7.96468C13 9.04523 12.9647 10.1732 12.9647 10.1732C12.9647 11.7344 11.7344 12.9647 10.1731 12.9647C10.1731 12.9647 8.99705 13 7.89401 13C6.86159 13 5.8622 12.9647 5.8622 12.9647C4.30097 12.9647 3.03533 11.699 3.03533 10.1378C3.03533 10.1378 3 8.99704 3 7.894C3 6.86158 3.03533 5.8622 3.03533 5.8622C3.03533 4.30097 4.30097 3.03533 5.8622 3.03533C5.8622 3.03533 6.9311 3 8 3C9.0689 3 10.1731 3.03533 10.1731 3.03533ZM10.0004 12.071C11.2806 12.071 12.071 11.2805 12.071 10.0003C12.071 10.0003 12.1 8.85704 12.1 7.97098C12.1 7.10426 12.071 5.99962 12.071 5.99962C12.071 4.7194 11.2806 3.92896 10.0004 3.92896C10.0004 3.92896 8.87652 3.89998 8 3.89998C7.12348 3.89998 6.03498 3.92896 6.03498 3.92896C4.7548 3.92896 3.92899 4.7194 3.92899 5.99962C3.92899 5.99962 3.9 7.06647 3.9 7.91305C3.9 8.81754 3.92899 9.96498 3.92899 9.96498C3.92899 11.2452 4.75478 12.071 6.03498 12.071C6.03498 12.071 7.0665 12.1 7.91308 12.1C8.81758 12.1 10.0004 12.071 10.0004 12.071Z"
        fill={props.color || '#717171'}
      />
    </SvgIcon>
  )
}
