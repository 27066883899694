import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} fill="none" width="16" height="16" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_6451_49315)">
        <path
          d="M8 0C3.57891 0 0 3.57891 0 8C0 12.4211 3.57891 16 8 16C12.4211 16 16 12.4219 16 8C16 3.57812 12.4219 0 8 0ZM10.1469 7.95797H8.75797V12.9688H6.69547V7.95797H5.64203V6.23203H6.69484V5.17922C6.69484 3.79031 7.28438 2.94797 8.92609 2.94797H10.315V4.67406H9.47266C8.84141 4.67406 8.79937 4.92688 8.79937 5.34734V6.18969H10.3569L10.1464 7.95813L10.1469 7.95797Z"
          fill={props.color || '#717171'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6451_49315">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
