import { SvgIcon } from '@mui/material'
import { omit } from 'lodash'

export default function icon(props) {
  const { favor, isMobile, stroke } = props

  let defaultStroke = 'white'
  if (isMobile) {
    defaultStroke = favor ? '#FF4F53' : '#050A22'
  }
  const svgProps = omit(props, ['stroke', 'isMobile', 'favor'])
  return (
    <SvgIcon {...svgProps} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M8.89986 14.2205C8.39319 14.6805 7.61319 14.6805 7.10652 14.2138L7.03319 14.1472C3.53319 10.9805 1.24652 8.90715 1.33319 6.32048C1.37319 5.18715 1.95319 4.10048 2.89319 3.46048C4.65319 2.26048 6.82652 2.82048 7.99986 4.19382C9.17319 2.82048 11.3465 2.25382 13.1065 3.46048C14.0465 4.10048 14.6265 5.18715 14.6665 6.32048C14.7599 8.90715 12.4665 10.9805 8.96652 14.1605L8.89986 14.2205Z"
        fill={favor ? '#FF4F53' : 'transparent'}
        stroke={stroke ?? defaultStroke}
        strokeWidth="1.5"
      />
    </SvgIcon>
  )
}
