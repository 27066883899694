import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M1.59998 2C1.59998 0.895429 2.49541 0 3.59998 0H10.4L14.4 4V13.2C14.4 14.3046 13.5045 15.2 12.4 15.2H3.59998C2.49541 15.2 1.59998 14.3046 1.59998 13.2V2Z"
        fill="#B0B0B0"
      />
      <path
        d="M4.79999 6.39844L9.59999 6.39844"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M4.79999 10.3984H11.2" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  )
}
