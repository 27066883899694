import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M1.22341 3.61906C1.21067 3.38978 1.39222 3.1987 1.62173 3.19122C2.75213 3.15439 5.22572 2.87755 7.99995 0.796875C10.7742 2.87755 13.2478 3.15439 14.3782 3.19122C14.6077 3.1987 14.7892 3.38978 14.7765 3.61906L14.4 10.3969C14 13.1969 10.2666 14.7969 7.99995 15.1969C5.73328 14.7969 1.99995 13.1969 1.59995 10.3969L1.22341 3.61906Z"
        fill="#B0B0B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4242 5.57417C12.6585 5.80849 12.6585 6.18839 12.4242 6.4227L8.1899 10.657C7.64317 11.2038 6.75673 11.2037 6.21 10.657L4.37569 8.8227C4.14137 8.58839 4.14137 8.20849 4.37569 7.97417C4.61 7.73986 4.9899 7.73986 5.22422 7.97417L7.05853 9.80849C7.13663 9.88659 7.26327 9.88659 7.34137 9.80849L11.5757 5.57417C11.81 5.33986 12.1899 5.33986 12.4242 5.57417Z"
        fill="white"
      />
    </SvgIcon>
  )
}
