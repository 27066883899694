import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import omit from 'lodash/omit'
import { loadImg } from '../../lib/loadImg'

const defaultUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8ff92PQAISAMWPUhmEwAAAABJRU5ErkJggg=='

const LazyImg = ({
  priority = false,
  url,
  useCurrentUrl = false,
  size = 400,
  usePlaceholder = true,
  fill = true,
  ...options
}) => {
  let src = url ? loadImg(url, size) : defaultUrl
  if (useCurrentUrl) {
    src = url || defaultUrl
  }

  const [imgUrl, setUrl] = useState(src)
  const [error, setError] = useState(false)
  const placeholder = usePlaceholder ? 'blur' : 'empty'
  useEffect(() => {
    setUrl((useCurrentUrl ? url : loadImg(url, size)) || defaultUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const onError = () => {
    // same url or big size img to ignore the error
    if (url === imgUrl || useCurrentUrl) return
    setUrl(url || defaultUrl)
    setError(true)
  }

  if (error) {
    return (
      <LazyImg
        url={imgUrl}
        priority={priority}
        useCurrentUrl
        usePlaceholder={useCurrentUrl}
        fill={fill}
        {...options}
      />
    )
  }

  return (
    <Image
      priority={priority}
      src={imgUrl}
      onError={onError}
      alt={options.alt || 'renty img'}
      placeholder={placeholder}
      blurDataURL={defaultUrl}
      fill={fill}
      {...omit(options, ['alt'])}
    />
  )
}

export default LazyImg
