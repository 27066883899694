import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 9.95372 1.80039 11.7205 3.09116 12.9903C3.37195 13.2666 3.29635 13.7639 2.92754 13.9022L2.06542 14.2255C1.64845 14.3818 1.76055 15 2.20587 15H8Z"
        fill="#B0B0B0"
      />
      <path
        d="M8.96862 7.97708C9.10789 7.22363 8.77601 6.87395 8.12373 7.04099C7.98483 7.07655 7.84516 7.11986 7.71421 7.18287C7.27937 7.39206 7.13688 7.76271 7.39971 8.22897C7.58708 8.56136 7.54443 8.8337 7.46521 9.157C7.30956 9.79214 7.15023 10.4272 7.02491 11.0708C6.91436 11.6387 7.17881 12.0053 7.6776 11.9999C7.74476 11.987 7.83919 11.9729 7.93211 11.9503C8.72628 11.7571 8.85319 11.4462 8.52263 10.5913C8.4636 10.4387 8.47123 10.2243 8.50636 10.0546C8.65025 9.3592 8.83947 8.6757 8.96862 7.97708Z"
        fill="white"
      />
      <path
        d="M8.79984 4.92307C8.80991 4.44182 8.34588 3.98424 7.86418 4.00042C7.36415 4.01724 6.96307 4.47318 7.0027 4.97986C7.04022 5.45966 7.43918 5.81597 7.9204 5.79945C8.3886 5.78337 8.79019 5.38317 8.79984 4.92307Z"
        fill="white"
      />
    </SvgIcon>
  )
}
