import { SvgIcon } from '@mui/material'

const Rental = props => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
    <rect width="16" height="16" fill="white" />
    <path
      d="M15.3223 6.91619L8.77577 1.29247C8.33015 0.910406 7.6778 0.901088 7.22299 1.27383L0.687972 6.62964C0.469756 6.8067 0.437597 7.13051 0.612171 7.3495C0.786744 7.57081 1.10603 7.60343 1.32195 7.42638L1.83648 7.00471V13.7653C1.83648 14.4455 2.38317 15 3.0539 15H5.2131C5.88383 15 6.43052 14.4455 6.43052 13.7653V10.3174C6.43052 10.201 6.5224 10.1078 6.63725 10.1078H9.141C9.25585 10.1078 9.34773 10.201 9.34773 10.3174V13.7653C9.34773 14.4455 9.89442 15 10.5651 15H12.6554C13.3262 15 13.8729 14.4455 13.8729 13.7653V7.01636L14.6676 7.69894C14.7618 7.78048 14.8789 7.82009 14.9938 7.82009C15.1362 7.82009 15.2786 7.75951 15.3797 7.63837C15.5612 7.42405 15.5336 7.10023 15.3223 6.91619Z"
      fill="#B0B0B0"
    />
  </SvgIcon>
)

export default Rental
