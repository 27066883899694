export const enum HEADER_LIST_KEY {
  LOGIN = 'login',
  LOGOUT = 'logout',
  FAVORITES = 'favorites',
  POLICY = 'policy',
  GUARANTEE = 'guarantee',
  RENTAL = 'rental',
  FAQ = 'faq',
  ABOUT = 'about',
  BLOG = 'blog',
  MARKET_TRENDS = 'market-trends'
}

export const ExternalHeaderUrls = {
  [HEADER_LIST_KEY.RENTAL]: 'https://portal.renty.ai/'
}
