import { useState, useEffect } from 'react'
import { Box, SvgIcon, Tooltip, ClickAwayListener } from '@mui/material'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import momentjs from 'moment'
import { useReactiveVar } from '@apollo/client'
import { showActive } from '@/state/active'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { calcRestFakeSport, useNoApplicationFeeActivity } from '../../lib/activity'
import Marquee from './marquee'
import { useActiveOpen, useActiveShow, setLastActiveDate, getLastActiveDate } from './activityState'

const Activity = () => {
  const [open, setOpen] = useActiveOpen(false)
  const [show, setShow] = useActiveShow(true)
  const display = useReactiveVar(showActive)
  const [verifyShow, setVerifyShow] = useState(false)
  const value = calcRestFakeSport()
  const { open: isNoApplicationFeeActivityOpen } = useNoApplicationFeeActivity()
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 3,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#757575'
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: '#60E2B8'
    }
  }))
  const showDetail = () => {
    if (isNoApplicationFeeActivityOpen) {
      setOpen(true)
    }
  }

  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setShow(false)
    setTimeout(() => {
      showActive(false)
      setLastActiveDate(momentjs().format('YYYY/MM/DD'))
    }, 160)
  }

  useEffect(() => {
    showActive(!!getLastActiveDate())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return display ? (
    <>
      <Box
        className={`sticky z-[1000] left-0 top-0 h-9 flex items-center w-full overflow-hidden transition-all text-white ${
          show ? 'opacity-100' : '-translate-y-full opacity-0'
        }`}
        sx={{ background: 'linear-gradient(315deg, #004D34 0%, #04AF78 100%)' }}
        onClick={showDetail}
      >
        <Box className="flex-1 overflow-hidden flex items-center">
          <Marquee className="text-sm">
            💥 Save <span className="italic font-semibold">$360</span> per year!
            {isNoApplicationFeeActivityOpen && (
              <span style={{ paddingLeft: '8px' }}>
                🎉 Refund of Application Fee for the{' '}
                <span className="font-semibold italic">FIRST 100</span> applicants who sign a lease.
              </span>
            )}
          </Marquee>
        </Box>
        <Box
          className="cursor-pointer px-3 h-full flex items-center justify-center"
          onClick={handleClose}
        >
          <CloseRoundedIcon className="text-lg w-5 h-5 flex items-center justify-center text-white" />
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        classes={{ root: open ? 'z-[1400]' : 'z-[-1]', paper: 'bg-[transparent]' }}
      >
        <Box
          component="div"
          className="relative pt-2 rounded-t-lg"
          sx={{ background: 'linear-gradient(137deg, #000 0%, #002519 100%)' }}
        >
          <Box className="mb-6 pr-3 flex justify-end">
            <CloseRoundedIcon
              className="text-white text-xs h-8 w-8 flex items-center justify-center"
              onClick={() => setOpen(false)}
            />
          </Box>

          <Box component="div" className="flex flex-col text-white w-full pl-10 pr-8 pb-10">
            <Box className="w-full">
              <Box component="h2" className="text-xl leading-8 font-bold font-minion">
                💥 Massive rent special
              </Box>
              <Box component="div" className="text-sm mt-4">
                Properties rented through Renty.AI are{' '}
                <span className="text-[#60E2B8] font-bold italic">$30 off</span> per month, saving
                you <span className="text-[#60E2B8] font-bold italic">$360</span> per year.
                {/* Take an <span className="text-[#60E2B8] font-bold italic">extra $30 off</span> (per
                month) the discounted rental price of all properties, save{' '}
                <span className="text-[#60E2B8] font-bold italic">$360</span>(per year) */}
              </Box>
            </Box>
            <Box component="div" className="h-[1px] w-full opacity-20 bg-[#60E2B8] my-6" />
            <Box className="w-full">
              <Box component="h2" className="text-xl leading-8 font-bold font-minion">
                🎉 Refund of Application Fee
              </Box>
              <Box className="mt-2 w-fit">
                <Box className="flex items-center gap-1 mb-1">
                  <SvgIcon width="12" height="13" viewBox="0 0 12 13">
                    <path
                      d="M8.57853 0.5H4.72771C4.42153 0.5 4.14609 0.686114 4.03186 0.970186L1.91411 6.23668C1.71593 6.72951 2.07877 7.26649 2.60995 7.26649H4.44767L3.69335 11.2235C3.62292 11.5929 4.07757 11.8265 4.33395 11.5514C5.91934 9.84963 9.21657 6.30906 10.676 4.73149C10.8975 4.49214 10.7251 4.1088 10.399 4.1088H8.84446C8.58235 4.1088 8.40111 3.84677 8.49356 3.60151L9.28031 1.51456C9.46523 1.02405 9.10274 0.5 8.57853 0.5Z"
                      fill="#60E2B8"
                    />
                  </SvgIcon>
                  <Box className="text-[#60E2B8] text-xs">Only {value} spots left</Box>
                </Box>
                <BorderLinearProgress variant="determinate" value={value} />
              </Box>
              <Box component="div" className="text-sm mt-4">
                Application fee waived for the <br />
                <ClickAwayListener onClickAway={() => setVerifyShow(false)}>
                  <Tooltip
                    PopperProps={{ disablePortal: true }}
                    arrow
                    placement="top-start"
                    onClose={() => setVerifyShow(false)}
                    open={verifyShow}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    classes={{
                      arrow: 'text-white',
                      tooltip: 'bg-white text-primary text-xs py-3 px-4 rounded-xl'
                    }}
                    title="The definition of a new user is someone who has not registered on renty.ai using their phone number or email address."
                  >
                    <Box
                      component="span"
                      className="text-[#60E2B8] font-bold italic align-baseline inline-block"
                      onClick={() => setVerifyShow(true)}
                    >
                      FIRST 100
                      <InfoOutlinedIcon className="text-sm mx-1" />
                    </Box>
                  </Tooltip>
                </ClickAwayListener>
                applicants who sign a lease.
              </Box>
            </Box>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  ) : null
}

export default Activity
