import { SvgIcon } from '@mui/material'

export default function favorite(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <rect width="16" height="16" fill="white" />
      <path
        d="M13.3601 2.67365C11.5129 1.44964 9.23173 2.02085 8.0002 3.42166C6.76868 2.02085 4.48755 1.44284 2.64026 2.67365C1.66064 3.32646 1.04488 4.42807 1.00289 5.59087C0.90493 8.2293 2.312 11.3441 6.98559 14.5877C7.59805 15.1234 8.41723 15.1021 9.01481 14.5741C13.6884 11.3373 15.0885 8.2225 14.9975 5.58408C14.9555 4.42807 14.3398 3.32646 13.3601 2.67365Z"
        fill="#B0B0B0"
      />
      <path
        d="M11 4C11 4 12 4 12.5 5C12.9441 5.88813 12.5 7 12.5 7"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </SvgIcon>
  )
}
