import { useEffect, useState } from 'react'

/**
 * 阻止body滚动
 * @param scrollRef
 */
const usePreventBodyScrolling = (prevent: boolean) => {
  const [preOverflowValue, setPreOverflowValue] = useState('')

  useEffect(() => {
    const elem = document.body
    setPreOverflowValue(elem.style.getPropertyValue('overflow'))
  }, [])

  useEffect(() => {
    const elem = document.body
    if (prevent) {
      elem.style.setProperty('overflow', 'hidden')
    } else if (preOverflowValue) {
      elem.style.setProperty('overflow', preOverflowValue)
    } else {
      elem.style.removeProperty('overflow')
    }

    return () => {
      if (preOverflowValue) {
        elem.style.setProperty('overflow', preOverflowValue)
      }
    }
  }, [prevent, preOverflowValue])
}

export default usePreventBodyScrolling
