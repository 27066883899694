import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} fill="none" width="16" height="16" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_6451_49317)">
        <path
          d="M16 8C16 3.58182 12.4182 0 8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8ZM3.69236 10.8429C3.83055 10.8604 3.97164 10.8698 4.11418 10.8698C4.94255 10.8713 5.70473 10.5724 6.30909 10.0705C5.53527 10.0524 4.88291 9.50546 4.65818 8.75491C4.76582 8.77746 4.87709 8.79055 4.99055 8.79127C5.152 8.792 5.30764 8.77018 5.456 8.728C4.64727 8.54909 4.03782 7.78182 4.03782 6.86764C4.03782 6.85964 4.03782 6.85164 4.03782 6.84364C4.27636 6.98764 4.54836 7.07491 4.83855 7.088C4.36509 6.74545 4.05309 6.16364 4.05309 5.50909C4.05309 5.16291 4.14036 4.84 4.29236 4.56364C5.16436 5.71636 6.46691 6.48145 7.93527 6.57673C7.90473 6.43927 7.88945 6.296 7.88945 6.14982C7.88945 5.11636 8.68073 4.29164 9.65673 4.30764C10.1651 4.31636 10.6247 4.54982 10.9469 4.91636C11.3498 4.83927 11.728 4.69091 12.0691 4.48364C11.9375 4.91491 11.6567 5.27418 11.2916 5.49891C11.6495 5.45891 11.9898 5.36436 12.3069 5.22109C12.0698 5.58982 11.7702 5.912 11.4247 6.16945C11.4284 6.24945 11.4298 6.32945 11.4298 6.40945C11.4298 8.86327 9.65164 11.6931 6.40073 11.6916C5.40364 11.6924 4.47418 11.3796 3.69236 10.8429Z"
          fill={props.color || '#717171'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6451_49317">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
