import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { offsetTop } from '@/state/config'

const usePageOffsetTop = () => {
  const lastOffsetTop = useReactiveVar(offsetTop)

  const getOffsetTop = () => {
    setTimeout(() => {
      const header = document.querySelector<HTMLDivElement>('#header')
      const activity = document.querySelector<HTMLDivElement>('#activity')
      let top = lastOffsetTop
      if (header || activity) {
        const { height } = header?.getBoundingClientRect() || {}
        const { height: activityHeight } = activity?.getBoundingClientRect() || {}
        top = ~~height + 1 + ~~activityHeight
      }
      offsetTop(top)
    })
  }

  useEffect(() => {
    getOffsetTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [lastOffsetTop, offsetTop, getOffsetTop] as const
}

export default usePageOffsetTop
