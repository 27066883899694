import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M0.5 3C0.5 1.89543 1.39543 1 2.5 1H13.5C14.6046 1 15.5 1.89543 15.5 3V10C15.5 11.1046 14.6046 12 13.5 12H8.10555C7.7107 12 7.32469 12.1169 6.99615 12.3359L3.96028 14.3598C3.59608 14.6026 3.11777 14.2934 3.18974 13.8616L3.40297 12.5822C3.45376 12.2774 3.21874 12 2.90977 12H2.5C1.39543 12 0.5 11.1046 0.5 10V3Z"
        fill="#B0B0B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54089 5.40258C6.83961 5.40258 7.03172 5.12668 7.21078 4.88758C7.22382 4.87016 7.23816 4.85314 7.2539 4.83655C7.36327 4.72133 7.57642 4.59052 7.99817 4.59052C8.39421 4.59052 8.66961 4.78114 8.81006 4.99755C8.96005 5.22866 8.93049 5.42257 8.85931 5.51945C8.77053 5.64028 8.6603 5.75187 8.52708 5.87277C8.48655 5.90955 8.4366 5.95337 8.38303 6.00036C8.28725 6.08438 8.17993 6.17853 8.09435 6.26061C7.76175 6.57965 7.58247 6.92707 7.50666 7.32727C7.45106 7.6208 7.70444 7.86403 8.00318 7.86209C8.30187 7.86016 8.50058 7.59905 8.6292 7.32946C8.67382 7.23593 8.74068 7.14595 8.84005 7.05064C8.91452 6.9792 8.98146 6.92082 9.05806 6.85402C9.11471 6.80461 9.17667 6.75057 9.25084 6.68326C9.4051 6.54328 9.57674 6.3752 9.72858 6.16855C10.1386 5.61049 10.0427 4.90504 9.71531 4.40051C9.37833 3.88128 8.76523 3.5 7.99817 3.5C7.33437 3.5 6.81974 3.71655 6.47228 4.08265C6.25617 4.31034 6.12562 4.57764 6.05816 4.86602C5.99012 5.15689 6.24216 5.40258 6.54089 5.40258ZM8.00312 8.40736C7.7044 8.40736 7.46223 8.65496 7.46223 8.95368C7.46223 9.2524 7.7044 9.5 8.00312 9.5C8.30184 9.5 8.54401 9.2524 8.54401 8.95368C8.54401 8.65496 8.30184 8.40736 8.00312 8.40736Z"
        fill="white"
      />
    </SvgIcon>
  )
}
