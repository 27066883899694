import React from 'react'
import MobileHeader from './mobileHeader'
import PCHeader from './pcHeader'

const Header = ({
  isShowMap = true,
  isMobileDevice = false,
  showLogo = false,
  transparent = false,
  showActive = false,
  className = '',
  back = false,
  centerNode = null
}) => {
  if (isMobileDevice) {
    return (
      <MobileHeader
        back={back}
        showLogo={showLogo}
        transparentMode={transparent}
        showActive={showActive}
        className={className}
        centerNode={centerNode}
      />
    )
  }
  return (
    <PCHeader
      isShowMap={isShowMap}
      className={className}
      transparentMode={transparent}
      showActive={showActive}
    />
  )
}

export default Header
