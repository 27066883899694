import { SvgIcon } from '@mui/material'

export default function icon(props) {
  return (
    <SvgIcon {...props} fill="none" width="16" height="16" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_6451_49322)">
        <path
          d="M12.224 9.904C12.056 10.5286 11.8486 10.832 11.272 10.944C11.272 10.944 9.77657 11.0566 8.216 11.0566C6.496 11.0566 4.688 10.944 4.688 10.944C4.104 10.872 3.832 10.488 3.70457 9.904C3.70457 9.904 3.53657 8.896 3.53657 7.896C3.53657 6.928 3.70457 5.96057 3.70457 5.96057C3.87257 5.36057 4.032 4.992 4.68743 4.92C4.68743 4.92 6.312 4.832 7.952 4.832C9.60857 4.832 11.272 4.92 11.272 4.92C11.8966 4.992 12.0406 5.33657 12.224 5.96057C12.224 5.96057 12.392 7.00857 12.392 8.024C12.392 8.976 12.224 9.904 12.224 9.904ZM8 0C3.576 0 0 3.57657 0 8C0 12.416 3.58457 16 8 16C12.416 16 16 12.416 16 8C16 3.57657 12.416 0 8 0Z"
          fill={props.color || '#717171'}
        />
        <path
          d="M7.02295 9.53523L9.58295 7.8798L7.02295 6.22266V9.53523Z"
          fill={props.color || '#717171'}
        />
      </g>
      <defs>
        <clipPath id="clip0_6451_49322">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
