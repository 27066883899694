import dayjs from 'dayjs'
// import QUERY_NUM_OF_APPLIED_FROM_RENT_AI from '@/graphqls/queryNumOfAppliedFromRentyAi.gql'

// import { useLazyQuery } from '@apollo/client'
// import { useEffect } from 'react'

export const FAKE_SPOTS_TOTAL_COUNT = 100
export const RENTY_DISCOUNT = 30
const ONLINE_DAY = dayjs(1691337600000) // 上线的北京时区时间 2023-08-07 00:00:00

// renty.ai 第一期活动 mock剩余优惠房源
export const calcRestFakeSport = () => {
  const now = dayjs(new Date().getTime()) // 当地时间转换为utc时间时间戳
  const days = now.diff(ONLINE_DAY, 'day')
  // rest 100 - 0循环
  const rest = FAKE_SPOTS_TOTAL_COUNT - (days % 100)
  return rest
}

// renty.ai 第一期活动 mock applying申请人数1-5的随机数
export const calcApplyingFakeUser = () => Math.floor(Math.random() * (5 - 1 + 1)) + 1

// const isRequested = false
const isNoApplicationFeeActivityOpen = false
export const useNoApplicationFeeActivity = () =>
  // const [queryNumOfAppliedFromRentyAI] = useLazyQuery(QUERY_NUM_OF_APPLIED_FROM_RENT_AI, {
  //   onCompleted(data) {
  //     const { numOfConvertedTenantsFromRentyAI = 100 } = data || {}
  //     isNoApplicationFeeActivityOpen = numOfConvertedTenantsFromRentyAI < 100
  //     isRequested = true
  //   },
  //   onError() {
  //     isRequested = true
  //   }
  // })
  // useEffect(() => {
  //   if (!isRequested) {
  //     queryNumOfAppliedFromRentyAI()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  ({ open: isNoApplicationFeeActivityOpen })
