import { Box } from '@mui/material'

const Marquee = ({ children, className }) => (
  <>
    <style jsx global>
      {`
        @keyframes textSlide {
          0% {
            transform: translateX(0);
          }
          to {
            transform: translateX(-50%);
          }
        }
      `}
    </style>
    <Box className={`${className} flex`} sx={{ animation: 'textSlide 12s linear 2s infinite' }}>
      <Box className="whitespace-nowrap pl-[196px]">{children}</Box>
      <Box className="whitespace-nowrap pl-[196px]">{children}</Box>
    </Box>
  </>
)
export default Marquee
